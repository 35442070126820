import styled from "styled-components/macro";

interface LiveIconProps {
  size: number;
}

const LiveIconDiv = styled.div<LiveIconProps>`
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: 45px;
    background-color: var(--green);
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--green);
    border-radius: 30px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes circle {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

function LiveIcon() {
  return <LiveIconDiv size={10} />;
}

export default LiveIcon;
